import Icon from "@material-ui/core/Icon";
import React from "react";

import * as S from "./SearchInput.module.scss";

const AutoCompleteList = ({
  autoCompleteArray,
  handleMouseOver,
  selectTarget,
  suggestionIndex,
}) => {
  return (
    <div className={S.suggestion}>
      {autoCompleteArray.map((item, index) => {
        return (
          <div
            key={`item${index}`}
            className={suggestionIndex === index ? S.focus : ""}
            onMouseOver={() => handleMouseOver(index)}
            onClick={() => selectTarget(index)}>
            <Icon className={S.history}></Icon>
            <span className={S.text}>{item.text}</span>
          </div>
        );
      })}
    </div>
  );
};

export default AutoCompleteList;
