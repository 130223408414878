import { ReplaceKeyword } from "@components/ReplaceKeyword";
import { Icon } from "@material-ui/core";
import Stars from "@material-ui/icons/Stars";
import * as E from "@pages/explore.module.scss";
import { iconManager } from "@utils/icon-manager";
import { leftPercent } from "@utils/video-utils";
import React, { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import ItemsCarousel from "react-items-carousel";

import * as S from "./AnnotationViewer.module.scss";

const videoWidth = 1000;
const maxLeft = 993;
const AnnotationViewer = ({
  sectionMarker,
  importantMarker,
  keyword,
  duration,
}) => {
  const intl = useIntl();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [clickDropdown, setClickDropdown] = useState(false);

  const arrowIcon = (icon) => {
    let arrow;
    switch (icon) {
      case "left":
        arrow = (
          <div className={`${E.iconBox} ${E.left}`}>
            <Icon>keyboard_arrow_left</Icon>
          </div>
        );
        break;
      case "right":
        arrow = (
          <div className={`${E.iconBox} ${E.right}`}>
            <Icon>keyboard_arrow_right</Icon>
          </div>
        );
        break;
      default:
        return null;
    }
    return arrow;
  };

  return (
    <section className={S.annotationSectionMarkerWrapper}>
      <div
        className={S.controller}
        onClick={() => setClickDropdown(!clickDropdown)}>
        <div className={S.resultText}>
          {intl.formatMessage(
            {
              id: "explore.results.videoDetailResultsFound",
            },
            { count: importantMarker.length },
          )}
        </div>
        <div className={S.icon}>
          {iconManager(clickDropdown ? "arrowUp" : "arrowDown")}
        </div>
      </div>
      {clickDropdown ? (
        <Fragment>
          <div className={S.annotationSectionMarker}>
            {importantMarker.map((marker, index) => {
              const left = leftPercent(
                marker.time,
                duration,
                videoWidth,
                maxLeft,
              );
              return (
                <div
                  style={{ left: `${left}%` }}
                  className={
                    activeItemIndex === index
                      ? S.annotationPointFocus
                      : S.annotationPoint
                  }
                  onClick={() => setActiveItemIndex(index)}
                  key={`point${index}`}
                />
              );
            })}

            {sectionMarker.map((item, index) => {
              let color;
              switch (index) {
                case 0:
                  color = S.blue;
                  break;
                case 1:
                  color = S.green;
                  break;
                case 2:
                  color = S.purple;
                  break;
                case 3:
                  color = S.origin;
                  break;
                case 4:
                  color = S.navyBlue;
                  break;
                default:
                  return null;
              }
              return (
                <div
                  className={color}
                  style={{ width: "100%" }}
                  key={`annotationSection${index}`}></div>
              );
            })}
          </div>
          <div className={S.annotationCardWrapper}>
            <ItemsCarousel
              infiniteLoop
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={4}
              gutter={8}
              leftChevron={arrowIcon("left")}
              rightChevron={arrowIcon("right")}
              outsideChevron
              chevronWidth={40}>
              {importantMarker.map((item, index) => {
                return (
                  <div
                    className={S.annotationCard}
                    key={`annotationImportant${index}`}
                    onClick={() => setActiveItemIndex(index)}>
                    <Stars className={S.star} />
                    <span className={S.annotationContent}>
                      {ReplaceKeyword(item.title, keyword)}
                    </span>
                  </div>
                );
              })}
            </ItemsCarousel>
          </div>
        </Fragment>
      ) : null}
    </section>
  );
};

export default AnnotationViewer;
