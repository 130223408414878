import Image from "next/image";
import React from "react";
import { useIntl } from "react-intl";

import {
  content,
  imgBox,
  notFoundPageInner,
  resultNotFoundPage,
  subTitle,
  title,
} from "./ResultNotFound.module.scss";

const ResultNotFound = ({ learnerSearchNone }) => {
  const intl = useIntl();
  return (
    <div className={resultNotFoundPage}>
      <div className={notFoundPageInner}>
        <div className={imgBox}>
          <Image
            src={"/images/course_no_items.png"}
            width="238"
            height="175"
            alt="no_item"
          />
        </div>
        <div className={content}>
          <div className={title}>
            {intl.formatMessage({
              id: "studio.course.noCoursesSearchFound",
            })}
          </div>
          <div className={subTitle}>
            {learnerSearchNone
              ? intl.formatMessage({
                  id: "myJournal.learner.anotherTip",
                })
              : intl.formatMessage({
                  id: "studio.course.anotherTip",
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultNotFound;
