import dayjs from "@utils/dayjs-utils";
import formatDistance from "date-fns/formatDistance";
import parseJSON from "date-fns/parseJSON";

/**
 * NOTE: parseJSON("Fri Jun 24 03:38:08 GMT 2022") => NaN
 * parseJSON supported date string
 * ----
 * 2000-03-15T05:20:10.123Z: The output of .toISOString() and JSON.stringify(new Date())
 * 2000-03-15T05:20:10Z: Without milliseconds
 * 2000-03-15T05:20:10+00:00: With a zero offset, the default JSON encoded format in some other languages
 * 2000-03-15T05:20:10+05:45: With a positive or negative offset, the default JSON encoded format in some other languages
 * 2000-03-15T05:20:10+0000: With a zero offset without a colon
 * 2000-03-15T05:20:10: Without a trailing 'Z' symbol
 * 2000-03-15T05:20:10.1234567: Up to 7 digits in milliseconds field. Only first 3 are taken into account since JS does not allow fractional milliseconds
 * 2000-03-15 05:20:10: With a space instead of a 'T' separator for APIs returning a SQL date without reformatting
 * ---
 */

const getUtcTime = () => {
  const now = new Date();
  const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  return utc;
};

export const formatDistanceTime = (targetTime, dateLocale) => {
  try {
    let targetDateTime;
    if (String(targetTime).includes("T") || String(targetTime).includes("+")) {
      // 2022-07-15T03:30:44.000+0000
      const parseLocalTime = parseJSON(
        dayjs(targetTime || new Date()).format(),
      ); // local time
      targetDateTime = new Date(
        parseLocalTime.getTime() + parseLocalTime.getTimezoneOffset() * 60000,
      ); // to utc time
    } else {
      // 2022-08-03 05:42:59
      const parseTime = parseJSON(dayjs(targetTime || new Date()).format()); // parse time is utc
      targetDateTime = new Date(parseTime);
    }
    const theUtcTime = getUtcTime();
    return formatDistance(targetDateTime, theUtcTime, {
      addSuffix: true,
      locale: dateLocale,
      includeSeconds: true,
    });
  } catch (error) {
    return "-";
  }
};
