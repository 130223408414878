import { scm } from "@components/AppEnv";
import { Provider, useAppContext } from "@components/context";
import SearchInput from "@components/SearchInput";
import AutoCompleteList from "@components/SearchInput/AutoCompleteList";
import SuggestionList from "@components/SearchInput/SuggestionList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "@page-components/Footer";
import Header from "@page-components/Header";
import ResultItemLayout from "@page-components/ResultItemLayout";
import ResultNotFound from "@page-components/ResultNotFound";
import SortSelect from "@page-components/SortSelect";
import { generatePageProps, pageAccess } from "@router/utils";
import {
  deleteSearchHistory,
  getAutoCompleteList,
  getSearchHistory,
  getSearchResult,
  getSearchResultsByTag,
  getTrendingList,
  postSearchHistory,
} from "@utils/apiServices/explore";
import { useCentralInfo } from "@utils/hooks/use-central-info";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useIntl } from "react-intl";

import * as S from "./relevance.module.scss";

const SEARCH_LIMIT_WORDS = scm.search.limit_words;

export const courseHandleSearch = (router, searchValue) => {
  if (!searchValue) {
    return router.push({
      pathname: "/",
    });
  }
  router.push(
    `/search?keyword=${encodeURIComponent(
      typeof searchValue === "string"
        ? searchValue.slice(0, SEARCH_LIMIT_WORDS)
        : searchValue.text.slice(0, SEARCH_LIMIT_WORDS),
    )}`,
    undefined,
    // shallow won't run getServerSideProps again.
    {
      shallow: router.pathname.indexOf("/search") !== -1,
    },
  );
};

const Search = (props) => {
  const router = useRouter();
  const intl = useIntl();
  const baseContext = useAppContext();
  const {
    tag: queryTag,
    keyword: queryKeyword,
    sortBy: querySortBy,
  } = props.query;
  const [tag, setTag] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [searchResultData, setSearchResultData] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState(querySortBy || "relevance");
  const [isLoading, setIsLoading] = useState(false);
  const { showPriceWeb: showPrice } = useCentralInfo({
    isPublic: true,
    keys: ["showPriceWeb"],
  });

  const searchContext = {
    ...baseContext,
    keyword,
    sortBy,
    tag,
  };

  const handleSearch = async (searchValue) => {
    courseHandleSearch(router, searchValue);
  };

  const renderSearchItemList = () =>
    searchResultData.map((data, index) => (
      <ResultItemLayout
        data={data}
        keyword={keyword}
        key={`resultItemLayout${index}`}
        showPrice={showPrice}
      />
    ));

  const fetchData = async (type = "scroll") => {
    setIsLoading(true);
    try {
      if (tag === null && keyword === null) {
        setIsLoading(false);
        return;
      }
      const searchFun = tag ? getSearchResultsByTag : getSearchResult;
      const finalPageNum = type === "init" ? 0 : pageNum;
      const searchData = tag || {
        keyword,
        sortBy,
        pageNum: finalPageNum,
      };
      const {
        totalCount: total = 0,
        data: newResultData = [],
        error,
      } = await searchFun(searchData);
      if (error) {
        throw error;
      }
      setTotalCount(total);
      setPageNum(finalPageNum + 1);
      const tempArr =
        type === "scroll"
          ? [...searchResultData, ...newResultData]
          : [...newResultData];
      setSearchResultData(tempArr);
      setHasMoreItems(tempArr.length < total);
    } catch (error) {
      console.error("fetchData", error);
    }
    setIsLoading(false);
  };

  const transferToSortOrder = (sortByType) => {
    switch (sortByType) {
      case "mostRecent":
        return "mostRecent";
      case "popularity":
        return "popularity";
      case "relevance":
      default:
        return "relevance";
    }
  };

  useEffect(() => {
    const { tag: queryTagValue, keyword: queryKeywordValue } = router.query;
    if (keyword !== queryKeywordValue) {
      setKeyword(queryKeywordValue);
    }
    if (tag !== queryTagValue) {
      setTag(queryTagValue);
    }
  }, [keyword, queryKeyword, queryTag, router, tag]);

  useEffect(() => {
    setSortBy(transferToSortOrder(querySortBy));
    setPageNum(0);
    setTotalCount(0);
    setSearchResultData([]);
    fetchData("init");
  }, [keyword, tag, querySortBy]);

  return (
    <Provider value={searchContext}>
      <Header {...props} />
      <div className={S.relevancePage}>
        <div className={S.searchWrapper}>
          <SearchInput
            defaultKeyword={keyword}
            autoComplete={true}
            suggestion={true}
            searchHistory={true}
            handleSearch={handleSearch}
            AutoCompleteList={AutoCompleteList}
            SuggestionList={SuggestionList}
            deleteSearchHistory={deleteSearchHistory}
            postSearchHistory={postSearchHistory}
            getAutoCompleteList={getAutoCompleteList}
            getSearchHistory={getSearchHistory}
            getTrendingList={getTrendingList}
          />
        </div>
        <div className={S.relevancePageInner}>
          <div className={S.topWrapper}>
            <div className={S.searchResult}>
              {(!isLoading && pageNum === 0) || pageNum > 0 ? (
                <>
                  {intl.formatMessage(
                    {
                      id: "studio.course.resultMatchCount",
                    },
                    { count: totalCount },
                  )}
                  <i>{tag || keyword}</i>
                </>
              ) : null}
            </div>
            {!tag ? (
              <div className={S.sortItem}>
                <span>
                  {intl.formatMessage({
                    id: "explore.results.sortBy",
                  })}
                </span>
                <SortSelect sortBy={sortBy} setSortBy={setSortBy} />
              </div>
            ) : null}
          </div>
          <InfiniteScroll
            dataLength={searchResultData.length}
            next={() => fetchData()}
            hasMore={hasMoreItems}>
            <div className={S.relevanceContainer}>
              {searchResultData.length ? renderSearchItemList() : null}
              {isLoading ? (
                <div className={S.loadingWrapper}>
                  <CircularProgress />
                </div>
              ) : null}
              {searchResultData.length === 0 && !isLoading ? (
                <ResultNotFound />
              ) : null}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <Footer {...props} />
    </Provider>
  );
};

export default Search;

export const getServerSideProps = async (ctx) => {
  const pageData = await pageAccess(ctx, "explore");
  const customProps = { query: ctx.query };
  const pageProps = generatePageProps({ pageData, customProps });
  return pageProps;
};
