import ReactHtmlParser from "html-react-parser";

export const ReplaceKeyword = (string, keyword) => {
  // avoid dangerous tag
  if (string) {
    const result = string
      .replace("<", `<span><</span>`)
      .replace(keyword, `<em>${keyword}</em>`);
    // typeof keyword string
    if (keyword && keyword.trim()) {
      return ReactHtmlParser(result);
    }
    return string;
  }
  return "";
};
