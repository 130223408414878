import { iconManager } from "@utils/icon-manager";

import S from "./ResultItem.module.scss";

export const iconForSkillLevel = (level) => {
  switch (level) {
    case 1:
      return iconManager("BeginnerLvl", S.gray500);
    case 2:
      return iconManager("IntermediateLvl", S.gray500);
    case 3:
      return iconManager("AdvancedLvl", S.gray500);
    default:
      return iconManager("BeginnerLvl", S.gray500);
  }
};
