import { formatDistanceTime } from "@utils/format-time-utils";

import { useFnsLanguage } from "./use-fns-language";

export const useFormatDistanceTime = () => {
  const dateLocale = useFnsLanguage();
  const localeFormatDistanceTime = (targetTime) =>
    formatDistanceTime(targetTime, dateLocale);
  return localeFormatDistanceTime;
};
