import Avatar from "@components/Avatar";
import { ReplaceKeyword } from "@components/ReplaceKeyword";
import { getCurrencyText } from "@constants/price-setting";
import ViewAllTooltip from "@page-components/ViewAllTooltip";
import { translateToSkillLevel } from "@utils/course-utils";
import { useFormatDistanceTime } from "@utils/hooks/use-format-distance-time";
import { useFormatDuration } from "@utils/hooks/use-format-duration";
import { iconManager } from "@utils/icon-manager";
import { getBgCSSCourseCoverImg } from "@utils/image-utils";
import { numberAbbr } from "@utils/number-utils";
import { getUserName } from "@utils/parse-utils";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import * as S from "./ResultItem.module.scss";
import SubResultItem from "./SubResultItem";
import { iconForSkillLevel } from "./utils";

const ResultItem = ({ data, keyword, showPrice }) => {
  const router = useRouter();
  const intl = useIntl();
  const formatDistanceTime = useFormatDistanceTime();
  const formatDuration = useFormatDuration();

  const [handleClickMoreArrow, setHandleClickMoreArrow] = useState(false);
  const { courseInfo, courseContent } = data;

  const directToCourse = (event, courseId) => {
    event.preventDefault();
    router.push(`/course/preview/${courseId}`);
  };

  const {
    coverImg,
    creator,
    description,
    duration,
    enrollCount,
    skillLevel,
    id,
    price,
    publishTime,
    title,
    viewCount,
  } = courseInfo || data;

  const { avatar = null } = creator || {};

  const showArrowDirection = (up) => {
    if (up) {
      return "arrowUp";
    }
    return "arrowDown";
  };
  return (
    <div className={S.itemWrapper}>
      <div className={S.itemWrapperInner}>
        <a
          className={S.videoWrapper}
          href={`/studio/myCourse/${id}`}
          onClick={(event) => directToCourse(event, id)}>
          <div className={S.img} style={getBgCSSCourseCoverImg(coverImg)}>
            {duration ? (
              <div className={S.videoTime}>{formatDuration(duration)}</div>
            ) : null}
            <div className={S.icon}>{iconManager("Course")}</div>
          </div>
        </a>
        <div className={S.contentWrapper}>
          <div className={S.itemType}>
            <div className={S.TypeBox}>
              <div className={S.icon}>{iconManager("Course")}</div>
              <h1 className={S.typeText}>
                {intl.formatMessage({ id: "common.itemType.course" })}
              </h1>
            </div>
            <div className={S.levelBox}>
              <div className={S.icon}>{iconForSkillLevel(skillLevel)}</div>
              <div className={S.levelText}>
                {intl.formatMessage({
                  id: `common.skillLevel.${translateToSkillLevel(skillLevel)}`,
                })}
              </div>
            </div>
          </div>
          <ViewAllTooltip>
            <h2 className={S.dataScience}>{ReplaceKeyword(title, keyword)}</h2>
          </ViewAllTooltip>
          <ViewAllTooltip>
            <p className={S.disc}>{ReplaceKeyword(description, keyword)}</p>
          </ViewAllTooltip>
          <div className={S.author}>
            <Avatar {...creator} width={24} src={avatar} isPointer={false} />
            <span className={S.publishedTime}>{getUserName(creator)}</span>
            {publishTime ? (
              <span className={S.publishedTime}>
                &#183;{" "}
                {intl.formatMessage(
                  {
                    id: "studio.course.publishDays",
                    defaultMessage: "Publish • ",
                  },
                  {
                    time: formatDistanceTime(publishTime),
                  },
                )}
              </span>
            ) : null}
          </div>
          {showPrice ? (
            <div className={S.priceItem}>
              {getCurrencyText({
                ...price,
                intl,
              })}
            </div>
          ) : null}
        </div>
      </div>
      <div className={S.bottomWrapper}>
        {courseContent?.items?.length ? (
          <div
            className={S.controller}
            onClick={() => setHandleClickMoreArrow(!handleClickMoreArrow)}>
            <div className={S.resultText}>
              {intl.formatMessage(
                {
                  id: "explore.results.resultsFoundCount",
                },
                {
                  count: courseContent.items.length,
                },
              )}
            </div>
            <div className={S.icon}>
              {iconManager(showArrowDirection(handleClickMoreArrow))}
            </div>
          </div>
        ) : (
          <div className={S.controller}></div>
        )}

        <ul className={S.itemData}>
          <li>
            <div className={S.icon}>{iconManager("Views")}</div>
            <div className={S.number} title={viewCount}>
              {numberAbbr(viewCount)}
            </div>
          </li>
          <li>
            <div className={S.icon}>{iconManager("Enroll")}</div>
            <div className={S.number} title={enrollCount}>
              {numberAbbr(enrollCount)}
            </div>
          </li>
        </ul>
      </div>
      {handleClickMoreArrow && courseContent.items
        ? courseContent.items.map((item, index) => {
            return (
              <SubResultItem
                key={`subResultItem${index}`}
                data={item}
                keyword={keyword}
                courseInfoId={id}
              />
            );
          })
        : null}
    </div>
  );
};

export default ResultItem;
