import context from "@components/context";
import { customSelectStyleNoneBorder } from "@styles/components/customSelectStyles";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";

const SortSelect = ({ sortBy = "relevance", setSortBy }) => {
  const intl = useIntl();

  const router = useRouter();
  const { keyword } = useContext(context);
  const [renderSortBy, setRenderSortBy] = useState({
    value: sortBy,
    label: intl.formatMessage({ id: `search.${sortBy}` }),
  });
  useEffect(() => {
    setRenderSortBy({
      value: sortBy,
      label: intl.formatMessage({ id: `search.${sortBy}` }),
    });
  }, [sortBy]);
  const directToNewResult = (select) => {
    setSortBy(select.value);
    setRenderSortBy(select);
    router.push(
      `/search?keyword=${encodeURIComponent(keyword)}&sortBy=${select.value}`,
    );
  };
  const options = [
    {
      value: "relevance",
      label: intl.formatMessage({ id: "search.relevance" }),
    },
    {
      value: "mostRecent",
      label: intl.formatMessage({ id: "search.mostRecent" }),
    },
    {
      value: "popularity",
      label: intl.formatMessage({ id: "search.popularity" }),
    },
  ];
  return (
    <Select
      isSearchable={false}
      styles={customSelectStyleNoneBorder}
      options={options}
      onChange={directToNewResult}
      value={renderSortBy}
    />
  );
};

export default SortSelect;
