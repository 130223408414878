import ResultItem from "@page-components/ResultItem";
import React from "react";

import { itemContainer } from "./ResultItemLayout.module.scss";

const ResultItemLayout = ({ data, keyword, showPrice }) => (
  <div className={itemContainer}>
    <ResultItem data={data} keyword={keyword} showPrice={showPrice} />
  </div>
);

export default ResultItemLayout;
