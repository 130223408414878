import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";

import * as S from "./SearchInput.module.scss";

const SuggestionList = ({
  searchHistory = true,
  suggestionArray,
  handleMouseOver,
  selectTarget,
  suggestionIndex,
  deleteSearchHistory,
  setSuggestionArray,
}) => {
  const intl = useIntl();
  const removeSuggestion = async (index) => {
    if (searchHistory) {
      await deleteSearchHistory(suggestionArray[index].text);
    }
    const temp = [
      ...suggestionArray.slice(0, index),
      ...suggestionArray.slice(index + 1, suggestionArray.length),
    ];
    setSuggestionArray([...temp]);
  };

  const renderIcon = (type) => {
    if (type === "history") {
      return "query_builder";
    }
    return "trending_up";
  };

  const renderCloseIconButton = (type, index) => {
    if (type === "history") {
      return (
        <span className={S.close}>
          <IconButton onClick={() => removeSuggestion(index)}>
            <Close className={S.close} />
          </IconButton>
        </span>
      );
    }
    return null;
  };

  return (
    <div className={S.suggestion}>
      {suggestionArray.map((suggestionContent, index) => {
        return (
          <Fragment key={`suggestionContent${index}`}>
            {suggestionContent.text ? (
              <div className={S.suggestionList}>
                <div
                  className={clsx({
                    [S.focus]: suggestionIndex === index,
                  })}
                  onMouseOver={() => handleMouseOver(index)}
                  onClick={() => selectTarget(index)}>
                  <Icon
                    className={clsx({
                      [S.trending]: suggestionContent.type !== "history",
                      [S.history]: suggestionContent.type === "history",
                    })}>
                    {renderIcon(suggestionContent.type)}
                  </Icon>
                  <span className={S.text}>{suggestionContent.text}</span>
                </div>
                {renderCloseIconButton(suggestionContent.type, index)}
              </div>
            ) : null}
            {suggestionContent.type === "history" &&
            suggestionArray[index + 1] &&
            suggestionArray[index + 1].type === "trending" ? (
              <div className={S.categoryTitle}>
                {intl.formatMessage({ id: "search.whatsTrending" })}
              </div>
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
};

export default SuggestionList;
