import Avatar from "@components/Avatar";
import { ReplaceKeyword } from "@components/ReplaceKeyword";
import AnnotationViewer from "@page-components/AnnotationViewer";
import ViewAllTooltip from "@page-components/ViewAllTooltip";
import { translateToSkillLevel } from "@utils/course-utils";
import { useFormatDuration } from "@utils/hooks/use-format-duration";
import { iconManager } from "@utils/icon-manager";
import { getBgCSSCourseCoverImg } from "@utils/image-utils";
import { getUserName } from "@utils/parse-utils";
import clsx from "clsx";
import { useRouter } from "next/router";
import { filter } from "ramda";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";

import * as S from "./ResultItem.module.scss";
import { iconForSkillLevel } from "./utils";

const SubResultItem = ({ data, keyword, courseInfoId }) => {
  const router = useRouter();
  const intl = useIntl();
  const formatDuration = useFormatDuration();
  const {
    coverImg,
    creator,
    description,
    duration,
    learningTarget = [],
    tag = [],
    title,
    type,
    sectionMarker = [],
    importantMarker = [],
    skillLevel,
  } = data || {};

  const { avatar } = creator || {};

  const directToCoursePreview = (event, id) => {
    event.preventDefault();
    router.push(`/course/preview/${id}`);
  };

  const isMatch = (n) => {
    return n.length > 0;
  };
  return (
    <Fragment>
      <div
        className={clsx({
          [S.itemWrapper]: true,
          [S.subResultItem]: true,
        })}>
        <div className={S.itemWrapperInner}>
          <a
            className={S.videoWrapper}
            onClick={(event) => directToCoursePreview(event, courseInfoId)}>
            <div className={S.img} style={getBgCSSCourseCoverImg(coverImg)}>
              {duration ? (
                <div className={S.videoTime}>{formatDuration(duration)}</div>
              ) : null}
              <div className={S.icon}>{iconManager("Course")}</div>
            </div>
          </a>
          <div className={S.contentWrapper}>
            <div className={S.itemType}>
              <div className={S.TypeBox}>
                <div className={S.icon}>{iconManager(type)}</div>
                <h1 className={S.typeText}>
                  {intl.formatMessage({ id: `common.itemType.${type}` })}
                </h1>
              </div>
              <div className={S.levelBox}>
                <div className={S.icon}>{iconForSkillLevel(skillLevel)}</div>
                <div className={S.levelText}>
                  {intl.formatMessage({
                    id: `common.skillLevel.${translateToSkillLevel(
                      skillLevel,
                    )}`,
                  })}
                </div>
              </div>
            </div>
            <ViewAllTooltip>
              <h2 className={S.dataScience}>
                {ReplaceKeyword(title, keyword)}
              </h2>
            </ViewAllTooltip>
            <ViewAllTooltip>
              <p className={S.disc}>{ReplaceKeyword(description, keyword)}</p>
            </ViewAllTooltip>
            <div className={S.author}>
              <Avatar {...creator} width={24} src={avatar} isPointer={false} />
              <span className={S.publishedTime}>{getUserName(creator)}</span>
            </div>
            <section className={S.covered}>
              <div className={S.secTitle}>
                {intl.formatMessage({
                  id: "explore.results.whatYouWillLearningCovered",
                })}
              </div>
              <ul>
                {learningTarget.map((item, index) => {
                  return (
                    <li key={`learningTarget${index}`}>
                      {ReplaceKeyword(item, keyword)}
                    </li>
                  );
                })}
              </ul>
            </section>
            <section className={S.tags}>
              <div className={S.secTitle}>
                {intl.formatMessage({
                  id: "explore.results.tagsCovered",
                })}
              </div>
              <ul>
                {tag.map((item, index) => {
                  return (
                    <li key={`tag${index}`}>{ReplaceKeyword(item, keyword)}</li>
                  );
                })}
              </ul>
            </section>
            <section className={S.sectionCoveredItem}>
              {filter(isMatch, sectionMarker).length ? (
                <div className={S.secTitle}>
                  {intl.formatMessage({
                    id: "explore.results.videoSectionCovered",
                  })}
                </div>
              ) : null}

              <ul>
                {sectionMarker.map((item, index) => {
                  let color;
                  switch (index) {
                    case 0:
                      color = S.blue;
                      break;
                    case 1:
                      color = S.green;
                      break;
                    case 2:
                      color = S.purple;
                      break;
                    case 3:
                      color = S.origin;
                      break;
                    case 4:
                      color = S.navyBlue;
                      break;
                    default:
                      break;
                  }
                  if (item) {
                    return (
                      <li className={color} key={`sectionMarker${index}`}>
                        <span>{`0${index + 1}`}</span>
                        <span className={S.keyWord}>
                          {ReplaceKeyword(item, keyword)}
                        </span>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </section>
          </div>
        </div>
        {importantMarker.length ? (
          <div className={S.annotationWrapper}>
            <AnnotationViewer
              sectionMarker={sectionMarker}
              importantMarker={importantMarker}
              keyword={keyword}
              duration={duration}
            />
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default SubResultItem;
