const customSelectControl = (styles) => {
  return {
    ...styles,
    width: "100%",
    minWidth: "148px",
    height: "100%",
    borderColor: "#fff",
    boxShadow: "0px 1px 2px #00000029",
    borderRadius: "6px",
    "&:hover": {
      borderColor: "transparent",
    },
  };
};

const customSingleValue = (provided, state) => {
  const opacity = state.isDisabled ? 0.5 : 1;
  const transition = "opacity 300ms";
  const color = "#727171";
  return { ...provided, opacity, transition, color };
};

const customOption = (styles, { isFocused }) => {
  return {
    ...styles,
    backgroundColor: isFocused ? "#CAEAFE" : isFocused,
    borderBottom: "none",
    position: "relative",
    padding: "15px",
    color: "#727171",
  };
};

const customMenu = (styles) => {
  return {
    ...styles,
    width: "100%",
    boxShadow: "0px 1px 2px #00000029",
    border: "none",
    marginTop: 5,
  };
};

const customIndicatorSeparator = (styles) => {
  return {
    ...styles,
    display: "none",
  };
};

export const customSelectStylesRegular = {
  control: customSelectControl,
  indicatorSeparator: customIndicatorSeparator,
  menu: customMenu,
  option: customOption,
  singleValue: customSingleValue,
};

export const customSelectStylesMini = {
  control: customSelectControl,
  indicatorSeparator: customIndicatorSeparator,
  menu: (styles) => ({
    ...styles,
    width: "100%",
    boxShadow: "0px 1px 2px #00000029",
    border: "none",
    marginTop: 5,
    maxHeight: "250px",
    overflow: "hidden",
  }),
  option: customOption,
  singleValue: customSingleValue,
};

export const customSelectStyleNoneBorder = {
  control: (styles, { isFocused }) => ({
    ...styles,
    width: "100%",
    minWidth: "148px",
    height: "100%",
    border: "none",
    fontSize: "12px",
    borderColor: "transparent",
    boxShadow: isFocused ? "transparent" : isFocused,
  }),
  indicatorSeparator: customIndicatorSeparator,
  menu: (styles) => ({
    ...styles,
    width: "100%",
    border: "none",
    boxShadow: "0px 1px 2px #00000029",
    marginTop: 5,
    zIndex: "999",
    fontSize: "12px",
  }),

  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? "#CAEAFE" : isFocused,
    borderBottom: "none",
    position: "relative",
    padding: "15px",
    color: "#595757",
  }),

  singleValue: customSingleValue,
};

export const customSelectStylesGray = {
  control: (styles) => ({
    ...styles,
    width: "100%",
    minWidth: "148px",
    height: "100%",
    borderColor: "#e8e8e8",
    borderRadius: "6px",
    backgroundColor: "#e8e8e8",
    cursor: "pointer",
    "&:hover": {
      borderColor: "transparent",
    },
  }),
  indicatorSeparator: customIndicatorSeparator,
  menu: customMenu,
  option: customOption,
  singleValue: customSingleValue,
};
